<template>
    <div>
        <consulta-notas
            :paciente="dp.pacienteId"
            :permiteAgrega="false"
            :permiteImprimir="false"
        />
    </div>
</template>

<script>
export default {
    components:{
        'consulta-notas':()=>import('@/components/hospital/Notas'),
    },
    props:{
        dp:Object,
    },

}
</script>

<style>

</style>